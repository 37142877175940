import React from 'react';
import AppHeader from "./Sections/AppHeader";
import AppFooter from "./Sections/AppFooter";
import Hero from "./Sections/Hero";

import withRoot from "../withRoot";


function LandingPage() {
  return (
    <React.Fragment>
      <AppHeader/>
      <Hero/>
      {/*TODO: Ideally have some form, but will use mailto link in footer <Contact/>*/}
      <AppFooter/>
    </React.Fragment>
  )
}

export default withRoot(LandingPage);
