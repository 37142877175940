import React from 'react';
import theme from '../assets/jss/dragnlife/theme';
import {CssBaseline, ThemeProvider} from "@mui/material";

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Component {...props}/>
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
