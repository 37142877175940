import React, {Component} from "react";

import {container} from "assets/jss/material-kit-react.jsx";
import {Divider, List, ListItem, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  container,
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  }
});

class AppFooter extends Component {

  render() {
    const {classes} = this.props;

    return (
      <footer className={classes.footer}>
        <div className={classes.container}>
          <Divider/>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="mai&#108;t&#111;&#58;i&#110;&#102;o&#64;%&#54;4%&#55;2&#97;g%6&#69;l&#105;f&#37;6&#53;&#46;c%6Fm"
                  className={classes.block}
                  target={'_blank'}>Contact Us</a>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            <Typography variant={'body1'}>
              &copy; DragN Life LLC {1900 + new Date().getYear()}
            </Typography>
          </div>
        </div>
      </footer>
    );
  }
}

export default withStyles(styles)(AppFooter);
