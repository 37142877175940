import React, {Component} from 'react';

import headerImage from "assets/img/web-header.png";
import {Container} from "@mui/material";
import {withStyles} from "@mui/styles";

const styles = theme => ({
  background: {
    backgroundImage: `url(${headerImage})`,
    backgroundSize: '100% 40px',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: -2,
    maxWidth: '100%',
    maxHeight: 40,
  },
});

class AppHeader extends Component {

  render() {
    const {classes} = this.props;
    return (
      <Container className={classes.background}>
        <img style={{display: 'none'}} src={headerImage} alt=""/>
      </Container>
    )
  }
}

export default withStyles(styles)(AppHeader);
