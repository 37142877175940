import React, {Component} from 'react';

import SectionLayout from "./SectionLayout";

import heroLogo from "assets/img/hero-logo.png"
import {Container, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";

const styles = theme => ({
  background: {
    backgroundColor: '#ffffff',
    backgroundPosition: 'center',
  },
  layout: {
    alignItems: 'center',
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    height: 100,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  }
});

class Hero extends Component {

  render() {
    const {classes} = this.props;
    return (
      <SectionLayout className={classes.layout}>
        <Container className={classes.container}>
          <img src={heroLogo} alt="DragN Life" className={classes.image}/>
          <Typography variant={'h5'} align="center">Founded in a small room out in Queens, NY March
            2019</Typography>
        </Container>
      </SectionLayout>
    );
  }
}

export default withStyles(styles)(Hero);
