import {green, grey, red} from "@mui/material/colors";
import {createMuiTheme} from "@mui/material/styles";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#69696a',
      main: '#29394E',
      dark: '#1e1e1f',
    },
    secondary: {
      light: '#EEF9FC',
      main: '#69CDAD',
      dark: '#407A80',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em",
  },
});

const fontHeader = {
  color: rawTheme.palette.primary.main,
  margin: "1.75rem 0 0.875rem",
  textDecoration: "none",
  fontWeight: "700",
  fontFamily: `"Roboto Slab", "Times New Roman", serif`
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    color: rawTheme.palette.primary.main,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      color: rawTheme.palette.primary.main,
      fontWeight: rawTheme.typography.fontWeightLight,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      color: rawTheme.palette.primary.main,
      fontWeight: rawTheme.typography.fontWeightLight,
      fontSize: 14,
    },
  },
};

export default theme;
