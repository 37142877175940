import React, {Component} from 'react';
import clsx from "clsx";
import {withStyles} from "@mui/styles";

const styles = theme => ({
  root: {
    color: theme.palette.primary.main,
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: '80vh',
      minHeight: 500,
      maxHeight: 500,
    },
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
  }
});

class SectionLayout extends Component {

  render() {
    const {backgroundClassName, children, classes, className} = this.props;

    return (
      <section className={clsx(classes.root, className)}>
        <div className={clsx(classes.background, backgroundClassName)}/>
        {children}
      </section>
    );
  }
}

export default withStyles(styles)(SectionLayout);
